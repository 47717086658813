import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import React from 'react';
import Btn, { BtnProps } from '../ui/Btn';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardMetaProps<D extends React.ElementType = 'button'> = BtnProps<D>;

export const CardMeta = <D extends React.ElementType>({ children, ...props }: CardMetaProps<D>) => {
  const { meta } = useCard();
  const content = children ?? meta;

  if (!content) return null;

  return (
    <Btn variant="text" {...mergePropsClassName(props, style.meta)}>
      {content}
    </Btn>
  );
};
