import React from 'react';
import Flex from '../ui/Flex';

const MenuList = ({ children }: React.PropsWithChildren) => {
  return (
    <Flex alignItems="start" rowGap="2xs">
      {children}
    </Flex>
  );
};

export default MenuList;
