'use client';

import ImageAsset from '@/components/ImageAsset';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import VideoAsset from '@/components/VideoAsset';
import { gql } from '@/graphql/__generated__';
import { parseCallToActionMultiple } from '@/lib/parse/callToActionMultiple';
import { useBreakpointMin } from '@/lib/utils/useBreakpoints';
import { toBoolean } from '@liquorice/utils';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';
import * as styles from './TextImageBlock.css';

const TEXT_IMAGE_BLOCK = gql(`
  fragment textImageBlock on blocks_textImage_BlockType {
    content: htmlContentDefault
    callToActionMultiple {
      ...callToActionMultiple
    }
    flipAlignment
    assetSingle {
      ...ImageAsset
    }
    externalUrl
    blockTheme {
      ...blockTheme
    }
  }
`);

const TextImageBlock = createBlock(TEXT_IMAGE_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const lg = useBreakpointMin('lg');

  const { assetSingle, content } = data;
  const links = parseCallToActionMultiple(data.callToActionMultiple);
  const flipAlignment = toBoolean(data.flipAlignment);

  const Media = () => (
    <Grid.Col lg={6}>
      <ImageAsset data={assetSingle} />
      {data.externalUrl && (
        <VideoAsset playing={true} muted={true} controls={false} videoUrl={data.externalUrl} />
      )}
    </Grid.Col>
  );

  const Text = () => (
    <Grid.Col lg={6}>
      {content && (
        <Txt html className={styles.content}>
          {content}
        </Txt>
      )}
      {links?.map((link, i) => {
        return (
          link?.href && (
            <Btn
              key={i}
              variant="text"
              startIcon="arrowRight"
              animateIcon
              animateDirection="right"
              {...link}
            />
          )
        );
      })}
    </Grid.Col>
  );

  return (
    <BlockContainer meta={meta} marginY>
      <Grid rowGutter>
        {flipAlignment && lg ? (
          <>
            <Media />
            <Text />
          </>
        ) : (
          <>
            <Text />
            <Media />
          </>
        )}
      </Grid>
    </BlockContainer>
  );
});

export default TextImageBlock;
