import Box, { BoxProps } from '@/components/ui/Box';
import NextAnchor from '@/components/ui/NextAnchor';
import { mergeProps, mergePropsClassName } from '@liquorice/utils';
import * as style from './Logo.css';
import { LogoSvg_Type } from './Logo.Type';

export type LogoProps = BoxProps<
  'span',
  {
    homeLink?: boolean;
    variant?: 'primary';
    SvgProps?: LogoSvgProps;
  }
>;

export type LogoSvgProps = React.ComponentPropsWithoutRef<'svg'> & {
  /** force monochrome */
  mono?: boolean;
};

const Logo = ({ homeLink, variant = 'primary', SvgProps, ...props }: LogoProps) => {
  let SvgComponent;

  switch (variant) {
    case 'primary':
      SvgComponent = LogoSvg_Type;
      break;
    default:
      SvgComponent = LogoSvg_Type;
  }

  let inner = <SvgComponent {...mergePropsClassName(SvgProps, style.svg)} />;

  if (homeLink)
    inner = (
      <NextAnchor className={style.link} href="/">
        {inner}
      </NextAnchor>
    );

  return <Box {...mergeProps(props, { className: style.root })}>{inner}</Box>;
};

export default Logo;
