import { BlockContainerProps } from '@/components/Blocks/BlockContainer';
import { getFragmentData } from '@/graphql/__generated__';
import { BlockProps, MaskedBlockProps } from '@/lib/parse/blocks';
import { TypedDocumentNode } from '@apollo/client';

export const createBlock = <TType, R extends JSX.Element | null>(
  fragment: TypedDocumentNode<TType>,
  BlockComponent: (props: BlockProps<TType>) => R
) => {
  function Block<T extends TType>(props: MaskedBlockProps<T> & BlockContainerProps) {
    const { data: maybeFragmentData, meta, ...rest } = props;

    const data = getFragmentData(fragment, maybeFragmentData) ?? null;

    if (!data) return null;

    return (
      <BlockComponent
        {...({
          data,
          meta,
          ...rest,
        } as BlockProps<TType>)}
      />
    );
  }

  return Block;
};
