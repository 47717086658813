import { Color } from '@/styles/partials/palette.css';
import { create } from 'zustand';

export type NavBarResponsiveVariant = 'mobile' | 'desktop';

export type NavBar = {
  fallbackHeight: number;
  height?: number | undefined;
  setHeight: (height: number | undefined) => void;

  menu: boolean;
  menuTheme?: Color;

  setMenu: (menu: boolean) => void;
  setMenuTheme: (menuTheme: Color) => void;

  search?: boolean;
  setSearch: (search: boolean) => void;
};

export const useNavigationActions = create<NavBar>()((set) => ({
  fallbackHeight: 82,
  height: undefined,
  setHeight: (height?: number) => set(() => ({ height })),

  menu: false,
  menuTheme: 'white',

  setMenu: (menu: boolean) => set(() => ({ menu })),
  setMenuTheme: (menuTheme: Color) => set(() => ({ menuTheme })),

  search: false,
  setSearch: (search: boolean) => set(() => ({ search })),
}));
