'use client';

import { Drawer } from '@mui/material';
import React from 'react';
import { useNavigationActions } from '../Navigation';
import NavigationBar from '../Navigation/Navigation.Bar';
import Flex from '../ui/Flex';
import Grid from '../ui/Grid';
import * as styles from './Search.css';
import SearchResults from './Search.Results';

const SearchDrawer = ({ children }: React.PropsWithChildren) => {
  const { search, setSearch } = useNavigationActions();

  return (
    <Drawer
      open={search}
      anchor="top"
      elevation={0}
      hideBackdrop
      transitionDuration={{ enter: 500, exit: 500, appear: 500 }}
      onClose={() => setSearch(false)}
      PaperProps={{
        className: styles.root,
      }}>
      <Flex cx={{ pY: 'md', pX: 'xl' }}>
        <NavigationBar searchToggle />
        <Flex rowGap="xl" cx={{ pT: 'md', pB: '4xl', pX: 'xl' }}>
          <Grid rowGutter>
            <Grid.Col offsetMd={2} md={8}>
              {children}
            </Grid.Col>
            <Grid.Col offsetMd={2} md={8}>
              <SearchResults />
            </Grid.Col>
          </Grid>
        </Flex>
      </Flex>
    </Drawer>
  );
};

export default SearchDrawer;
