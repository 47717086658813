'use client';

import { parseUri } from '@liquorice/utils';
import { useNavigationActions } from '../Navigation';
import Btn, { BtnProps } from '../ui/Btn';

type MenuFooterItemProps = BtnProps<'a'>;

const MenuFooterBtn = ({ href: rawHref, id, target, children, ...props }: MenuFooterItemProps) => {
  const setMenu = useNavigationActions((s) => s.setMenu);
  const href = parseUri(rawHref);

  if (!href) return null;

  return (
    <Btn
      key={id}
      href={href}
      target={target}
      onClick={() => setMenu(false)}
      color="black"
      variant="text"
      size="large"
      wrap
      {...props}>
      {children}
    </Btn>
  );
};

export default MenuFooterBtn;
