'use client';

import { NavMenuItem } from '@/lib/parse/nav';
import { parseUri } from '@liquorice/utils';
import { useNavigationActions } from '../Navigation';
import Btn from '../ui/Btn';

const MenuFooterMenuItem = ({ id, href: rawHref, children }: NavMenuItem) => {
  const setMenu = useNavigationActions((s) => s.setMenu);
  const href = parseUri(rawHref);

  if (!href) return null;

  return (
    <Btn
      key={id}
      href={href}
      onClick={() => setMenu(false)}
      variant="text"
      size="large"
      startIcon="arrowUpRight"
      animateIcon
      animateDirection="diagonal">
      {children}
    </Btn>
  );
};

export default MenuFooterMenuItem;
