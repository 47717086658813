import { parseUri } from '@liquorice/utils';
import Card, { CardProps } from '../Card';
import Box from '../ui/Box';
import Flex from '../ui/Flex';

export type EntrySearchCard = {
  __typename: string;
  id: string;
  pageTitle?: string | null;
  entrySummary?: string | null;
  sectionHandle?: string | null;
  uri?: string | null;
};

type SearchCardProps = { data: EntrySearchCard } & Omit<CardProps, 'item'>;

const SearchCard = ({ data, ...props }: SearchCardProps) => {
  const { pageTitle, entrySummary, sectionHandle, uri } = data;

  const href = parseUri(uri);
  const handle = sectionHandle?.replace(/([A-Z])/g, ' $1').trim();

  return (
    href && (
      <Card
        elevation={false}
        item={{ title: pageTitle, description: entrySummary, meta: handle, href }}
        cx={{ pY: 'md' }}
        {...props}>
        <Card.Body>
          <Flex
            direction="row"
            justifyContent="between"
            alignItems="center"
            spacing="lg"
            cx={{ width: '100' }}>
            <Flex spacing="2xs">
              <Card.Title />
              <Card.Description />
            </Flex>
            <Box>
              <Card.Meta variant="outlined" size="small" color="black" uppercase />
            </Box>
          </Flex>
        </Card.Body>
      </Card>
    )
  );
};

export default SearchCard;
