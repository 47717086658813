'use client';

import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import { parseCallToAction } from '@/lib/parse/callToAction';
import { parseTextAlignment } from '@/lib/parse/textAlignment';
import { parseBlockTheme } from '@/lib/parse/theme';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';

const STATEMENT_BLOCK = gql(`
  fragment statementBlock on blocks_statement_BlockType {
    genericText
    textAlignment
    callToAction {
      ...callToAction
    }
    blockTheme {
      ...blockTheme
    }
  }
`);

const StatementBlock = createBlock(STATEMENT_BLOCK, ({ data, meta }) => {
  if (!data) return null;

  const blockTheme = parseBlockTheme(data.blockTheme);
  const link = parseCallToAction(data.callToAction);
  const textAlignment = parseTextAlignment(data.textAlignment);

  const center = textAlignment === 'center';
  const right = textAlignment === 'right';

  return (
    <BlockContainer meta={meta} blockTheme={blockTheme} marginY>
      <Grid>
        <Grid.Col offsetMd={center ? 1 : right ? 2 : undefined} md={10}>
          <Flex alignItems={center ? 'center' : right ? 'end' : 'start'}>
            <Txt variant="h2" noMargin={!link} cx={{ textAlign: center ? 'center' : undefined }}>
              {data.genericText}
            </Txt>
            {link && <Btn variant="outlined" uppercase size="normal" {...link} />}
          </Flex>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default StatementBlock;
