import { useEffect } from 'react';
import { create } from 'zustand';

export type BooleanValueState = {
  value: boolean;
  setValue: (value: boolean) => void;
};

export const useNextHydratedStore = create<BooleanValueState>((set) => ({
  value: false,
  setValue: (value: boolean) => set({ value }),
}));

export const useNextHydrated = () => {
  const { value, setValue } = useNextHydratedStore();

  useEffect(() => {
    setValue(true);
    return () => setValue(false);
  }, [setValue]);
  return value;
};
