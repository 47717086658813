import Logo from '../Logo';
import Box from '../ui/Box';
import Flex, { FlexProps } from '../ui/Flex';
import * as styles from './Navigation.css';
import NavigationSearch from './Navigation.Search';
import NavigationToggle from './Navigation.Toggle';

type NavigationBarProps = FlexProps<
  'div',
  {
    navToggle?: boolean;
    searchToggle?: boolean;
  }
>;

export default function NavigationBar({
  cx,
  navToggle = false,
  searchToggle = false,
  ...props
}: NavigationBarProps) {
  return (
    <Flex
      as="ul"
      direction="row"
      justifyContent="between"
      alignItems="center"
      cx={{ listStyle: 'none', ...cx }}
      {...props}>
      <Box as="li" className={styles.logo}>
        <Logo homeLink />
      </Box>
      <Flex as="li" direction="row" spacing="md">
        <NavigationSearch open={searchToggle} />
        <NavigationToggle open={navToggle} />
      </Flex>
    </Flex>
  );
}
