'use client';

import { SEARCH_FRAGMENT } from '@/graphql/fragments/search';
import { useFragment } from '@apollo/client';
import React from 'react';
import SearchCard, { EntrySearchCard } from '../Search/Search.Card';

export type ResultProps = {
  id: string;
  typeName: string;
};

export default function Result({ id, typeName }: ResultProps) {
  const { data } = useFragment({
    fragment: SEARCH_FRAGMENT,
    fragmentName: 'search',
    from: { __typename: typeName, id },
  });

  const [entry, setEntry] = React.useState<EntrySearchCard>();

  React.useEffect(() => {
    if (data) setEntry(data as EntrySearchCard);
  }, [data]);

  if (!entry) return null;

  return <SearchCard data={entry} />;
}
