import type { LogoSvgProps } from './Logo';

export const LogoSvg_Type = (props: LogoSvgProps) => (
  <svg viewBox="0 0 163 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.0628 14.6103C64.0628 18.2052 62.2994 19.8173 59.0958 19.8173C55.8595 19.8173 54.0976 18.2052 54.0976 14.6103V0.30357H50.6006V14.8091C50.6006 20.2824 54.2621 23.019 59.0958 23.019C63.8968 23.019 67.5584 20.2824 67.5584 14.8091V0.30357H64.0628V14.6103ZM82.6461 19.8173C78.0392 19.8173 74.8074 16.2582 74.8074 11.5102C74.8074 6.76079 78.0392 3.20319 82.6461 3.20319C87.2486 3.20319 90.4893 6.76079 90.4893 11.5102C90.4893 16.2597 87.2486 19.8173 82.6461 19.8173ZM82.6461 0C76.2773 0 71.1784 4.88255 71.1784 11.5102C71.1784 18.1379 76.2773 23.0205 82.6461 23.0205C89.0164 23.0205 94.1093 18.1379 94.1093 11.5102C94.1093 4.88255 89.0164 0 82.6461 0Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.012 22.7169H120.511V0.30357H117.012V22.7169ZM163 3.43348V0.30357H148.398V22.7169H163V19.5885H151.895V13.0565H161.336V9.99539H151.895V3.43348H163ZM3.49854 0.30357H0V22.7169H13.2977V19.587H3.49854V0.30357ZM16.9222 22.7169H20.4148V0.30357H16.9222V22.7169ZM142.166 16.5543C140.502 18.7301 138.377 19.8173 135.504 19.8173C131.024 19.8173 127.754 16.3554 127.754 11.5102C127.754 6.76079 130.96 3.20319 135.37 3.20319C138.31 3.20319 140.201 4.25297 141.675 6.26581V6.26431L144.02 3.90753C142.101 1.44009 139.355 0 135.467 0C129.124 0 124.134 4.88255 124.134 11.5102C124.134 18.1379 129.03 23.0205 135.372 23.0205C139.451 23.0205 142.268 21.6582 144.504 18.9171L142.167 16.5543H142.166ZM101.228 3.39012H105.546C108.514 3.39012 109.725 5.50913 109.725 7.64608C109.725 9.78453 108.514 11.9035 105.546 11.9035H101.228V3.39012ZM113.29 7.64608C113.309 4.20811 111.324 0.30357 106.583 0.30357H97.7309V22.7169H101.228V14.9901H105.519L109.782 22.7169H113.517L108.925 14.528C111.974 13.4902 113.275 10.4246 113.29 7.64608ZM35.5055 19.8173C30.9001 19.8173 27.6638 16.2582 27.6638 11.5102C27.6638 6.76079 30.9001 3.20319 35.507 3.20319C40.1139 3.20319 43.3502 6.76229 43.3502 11.5102C43.3502 16.2612 40.1139 19.8173 35.507 19.8173M46.9747 11.5102C46.9747 4.88255 41.8758 0 35.5055 0C29.1338 0 24.0393 4.88255 24.0393 11.5102C24.0393 18.1379 29.1338 23.0205 35.507 23.0205C38.0602 23.0205 40.4118 22.2324 42.3085 20.8641L45.4203 24L47.0251 22.3849L47.883 21.5176L44.789 18.3907C46.1627 16.505 46.9747 14.1512 46.9747 11.5102Z"
      fill="currentColor"
    />
  </svg>
);
