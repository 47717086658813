import classNames from 'classnames';
import { SVGProps } from 'react';

export const Direction = ({ className }: SVGProps<SVGElement>) => {
  const holderClasses = classNames('direction', className);

  return (
    <svg
      id="Blue"
      className={holderClasses}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 935 935">
      <polygon
        className="direction-polygon"
        fill="#96d9ff"
        points="627.68 559.98 627.68 375.02 467.5 282.54 307.32 375.02 307.32 559.98 467.5 652.46 627.68 559.98"
      />
      <path
        className="direction-arrow"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.86px"
        d="m481.14,443.48l143.8-130.26,39.4,16.36-52.22,47.72-44.86-12.82-12.82-51.27,57.67-51.27,8.91,37.21"
      />
      <path
        className="direction-squiggle"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.86px"
        d="m596.58,425.82c32.04,102.53-53.84,173.02-128.16,173.02-134.57,0-175-157.48-89.72-230.7,49.31-42.33,119.12-37.24,161.46,12.07,33.86,39.45,29.09,105.01-10.11,139.15-39.27,34.21-99.74,24.63-125.42-8.09-27.15-34.6-15.88-82.08,10.07-102.91,29.51-23.68,72.24-22.03,94.08,9.82,16.49,24.04,3.58,54.27-14.63,67.68-19.72,14.53-42.22,7.57-51.22-5.92-7.56-11.34-4.61-28.75,5.73-37.63,8.27-7.1,25.39-7.12,32.49,1.15"
      />
    </svg>
  );
};
