import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import HomeCard from '../HomeCard';
import { ListCardRoot } from '../ListCard';

export const HOME_CARD = gql(`
  fragment homeCard on home_Entry {
    uri
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    pageTitle
  }
`);

const HomeEntryCard = withFragmentArray(HOME_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <HomeCard data={item} />;
});

export const HomeListCard = withFragmentArray(HOME_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default HomeEntryCard;
