import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import ArticleCard from '../ArticleCard';
import { ListCardRoot } from '../ListCard';

export const ARTICLE_CARD_FRAGMENT = gql(`
  fragment articleCard on article_Entry {
    __typename
    id
    uri
    pageTitle
    postDate @formatDateTime(format: "j F Y")
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    articleCategory {
      ...articleCategory
    }
    profileSingle {
      ...profileCard
    }
    articleMultiple {
      ... on article_Entry {
        entryImage {
          ...ImageAsset
        }
        entrySummary
        entryTheme {
          ...entryTheme
        }
        articleCategory {
          ...articleCategory
        }
      }
    }
  }
`);

const ArticleEntryCard = withFragmentArray(ARTICLE_CARD_FRAGMENT, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <ArticleCard data={item} />;
});

export const ArticleListCard = withFragmentArray(ARTICLE_CARD_FRAGMENT, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default ArticleEntryCard;
