import { gql } from '../__generated__';

export const CALL_TO_ACTION_MULTIPLE_URL_FRAGMENT = gql(`
  fragment callToActionMultipleUrl on callToActionMultiple_Url_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_MULTIPLE_ENTRY_FRAGMENT = gql(`
  fragment callToActionMultipleEntry on callToActionMultiple_Entry_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_MULTIPLE_CUSTOM_FRAGMENT = gql(`
  fragment callToActionMultipleCustom on callToActionMultiple_Custom_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_MULTIPLE_EMAIL_FRAGMENT = gql(`
  fragment callToActionMultipleEmail on callToActionMultiple_Email_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_MULTIPLE_PHONE_FRAGMENT = gql(`
  fragment callToActionMultiplePhone on callToActionMultiple_Phone_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_MULTIPLE_FRAGMENT = gql(`
  fragment callToActionMultiple on HyperLinkInterface {
    ...callToActionMultipleUrl
    ...callToActionMultipleEntry
    ...callToActionMultipleCustom
    ...callToActionMultipleEmail
    ...callToActionMultiplePhone
  }
`);
