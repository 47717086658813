'use client';

import { submitToCampaignMonitor } from '@/app/[locale]/api/actions/route';
import Box, { BoxProps } from '@/components/ui/Box';
import Container from '@/components/ui/Container';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { zodResolver } from '@hookform/resolvers/zod';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import Btn from '../ui/Btn';
import * as styles from './SubForm.css';
import { type ValidationSchema, validationSchema } from './SubFormSchema';

export const form = {
  initial: {
    height: '0',
  },
  closed: {
    height: 'auto',
    transition: {
      duration: 0.15,
    },
  },
  open: {
    height: 'auto',
    transition: {
      duration: 0.15,
    },
  },
};

type OkServerResponse = {
  status: 200;
  data: ValidationSchema;
};

type ErrorServerResponse = {
  status: 400;
  errors: FieldValues;
};

type ServerActionResponse = OkServerResponse | ErrorServerResponse;

const SubForm = (props: BoxProps) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const t = useTranslations('subForm');

  const methods = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: ValidationSchema) => {
    const formRequest = (await submitToCampaignMonitor(data)) as ServerActionResponse;

    if (formRequest.status === 400) {
      console.log('There was an error processing the form.', formRequest.errors);
      return;
    }

    if (formRequest.status === 200) {
      console.log('Form successfully submitted.', formRequest.data);
    }
  };

  return (
    <Box {...props}>
      <Container>
        <Grid>
          <Grid.Col md={8}>
            <Txt as="h2" variant="h2">
              {t('calloutMessage')}
            </Txt>
          </Grid.Col>
          <Grid.Col md={6}>
            <Box as="form" onSubmit={handleSubmit((data) => onSubmit(data))} noValidate={true}>
              {isSubmitSuccessful ? (
                <Txt as="h2" variant="h2" className={styles.successMessage}>
                  {t('thanksMessage')}
                </Txt>
              ) : (
                <Box cx={{ display: 'flex', flexDirection: 'column', gap: '2xl' }}>
                  <Txt as="label">
                    <Box
                      as="input"
                      autoComplete="Email"
                      aria-label="Email"
                      id="fieldEmail"
                      onClick={() => setIsActive(true)}
                      className={styles.email}
                      placeholder="Email address"
                      type="email"
                      {...register('email')}
                    />
                    {errors.email && (
                      <Box className={styles.error}>
                        <Txt as="p" variant="md" bold>
                          {errors.email?.message as string}
                        </Txt>
                      </Box>
                    )}
                  </Txt>
                  <AnimatePresence>
                    {isActive && (
                      <motion.div
                        variants={form}
                        initial="initial"
                        animate={isActive ? 'open' : 'closed'}>
                        <Txt as="label">
                          <Box
                            as="input"
                            aria-label="Name"
                            className={styles.name}
                            placeholder="Name"
                            type="text"
                            {...register('name')}
                          />
                          {errors.name && (
                            <Box className={styles.error}>
                              <Txt as="p" variant="md" bold>
                                {errors.name?.message as string}
                              </Txt>
                            </Box>
                          )}
                          <Txt as="p" variant="sm" autoMargin cx={{ mT: 'xl' }}>
                            {t('termsMessage')}
                          </Txt>
                        </Txt>
                        <Btn
                          variant="outlined"
                          size="large"
                          uppercase
                          type="submit"
                          cx={{ mT: 'xl' }}>
                          {t('signUp')}
                        </Btn>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </Box>
              )}
            </Box>
          </Grid.Col>
        </Grid>
      </Container>
    </Box>
  );
};

export default SubForm;
