import { gql } from '@/graphql/__generated__';

export const CLIENT_CATEGORY_FRAGMENT = gql(`
  fragment clientCategory on clientCategory_Category {
    __typename
    id
    uri
    title
    groupHandle
    enabled
  }
`);

export const ARTICLE_CATEGORY_FRAGMENT = gql(`
  fragment articleCategory on articleCategory_Category {
    __typename
    id
    uri
    title
    groupHandle
    enabled
  }
`);

export const SERVICE_CATEGORY_FRAGMENT = gql(`
  fragment serviceCategory on serviceCategory_Category {
    __typename
    id
    uri
    title
    groupHandle
    enabled
  }
`);

export const SERVICE_DETAIL_CATEGORY_FRAGMENT = gql(`
  fragment serviceDetailCategory on serviceDetailCategory_Category {
    __typename
    id
    uri
    title
    groupHandle
    enabled
  }
`);

export const PROFILE_ROLE_CATEGORY_FRAGMENT = gql(`
  fragment profileRoleCategory on profileRoleCategory_Category {
    __typename
    id
    uri
    title
    groupHandle
    enabled
  }
`);
