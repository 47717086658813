import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import ImageAsset from '../ImageAsset';
import Box from '../ui/Box';
import Txt from '../ui/Txt';

export const PROFILE_CARD = gql(`
  fragment profileCard on profile_Entry {
    entryImage {
      ...ImageAsset
    }
    entrySummary
    firstName
    lastName
    jobPosition
  }
`);

const ProfileEntryCard = withFragmentArray(PROFILE_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <Box>
      <Box>
        <ImageAsset data={item.entryImage} ratio="SQUARE" />
      </Box>
      <Txt as="h5" variant="h5" cx={{ marginTop: 'md', marginBottom: 'none' }}>
        {item.firstName} {item.lastName}
      </Txt>
      <Txt as="p" variant="sm" cx={{ marginTop: 'xs', marginBottom: 'none' }}>
        {item.jobPosition}
      </Txt>
    </Box>
  );
});

export default ProfileEntryCard;
