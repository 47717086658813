'use client';

import { vars } from '@/styles';
import { useEntrySearchStore } from '@liquorice/next-search';
import Result from '../Results/Result';
import Grid from '../ui/Grid';
import { EntrySearchCard } from './Search.Card';

const SearchResults = () => {
  const results = useEntrySearchStore((s) => s.results);

  return (
    <Grid>
      {results.map((result) => {
        return (
          <Grid.Col
            key={result.id}
            style={{
              borderBottom: `1px solid ${vars.palette.colors.lightGrey}`,
            }}>
            <Result id={result.id} typeName={(result as EntrySearchCard).__typename} />
          </Grid.Col>
        );
      })}
    </Grid>
  );
};

export default SearchResults;
