import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import { ListCardRoot } from '../ListCard';
import OverviewCard from '../OverviewCard';

export const OVERVIEW_CARD = gql(`
  fragment overviewCard on overview_Entry {
    uri
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    pageTitle
  }
`);

const OverviewEntryCard = withFragmentArray(OVERVIEW_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <OverviewCard data={item} />;
});

export const OverviewListCard = withFragmentArray(OVERVIEW_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default OverviewEntryCard;
