import { getFragmentData } from '@/graphql/__generated__';
import { CallToActionMultipleFragment } from '@/graphql/__generated__/graphql';
import {
  CALL_TO_ACTION_MULTIPLE_CUSTOM_FRAGMENT,
  CALL_TO_ACTION_MULTIPLE_EMAIL_FRAGMENT,
  CALL_TO_ACTION_MULTIPLE_ENTRY_FRAGMENT,
  CALL_TO_ACTION_MULTIPLE_FRAGMENT,
  CALL_TO_ACTION_MULTIPLE_PHONE_FRAGMENT,
  CALL_TO_ACTION_MULTIPLE_URL_FRAGMENT,
} from '@/graphql/fragments/callToActionMultiple';
import { deleteUndefined, toString, toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import { CallToAction } from './callToAction';

const parseLinks = (data: CallToActionMultipleFragment) => {
  const item = firstNonNullable(data);

  let fragment = null;

  if (item?.__typename === 'callToActionMultiple_Url_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_MULTIPLE_URL_FRAGMENT, item);
  if (item?.__typename === 'callToActionMultiple_Entry_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_MULTIPLE_ENTRY_FRAGMENT, item);
  if (item?.__typename === 'callToActionMultiple_Email_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_MULTIPLE_EMAIL_FRAGMENT, item);
  if (item?.__typename === 'callToActionMultiple_Phone_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_MULTIPLE_PHONE_FRAGMENT, item);
  if (item?.__typename === 'callToActionMultiple_Custom_LinkType')
    fragment = getFragmentData(CALL_TO_ACTION_MULTIPLE_CUSTOM_FRAGMENT, item);

  if (!fragment) return null;

  const { target, type, text, title, url: href } = fragment;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: CallToAction = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseCallToActionMultiple = createFragmentArrayParser(
  CALL_TO_ACTION_MULTIPLE_FRAGMENT,
  (data) => {
    if (!data) return null;

    return data.map((item) => parseLinks(item));
  }
);
