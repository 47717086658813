import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import { ListCardRoot } from '../ListCard';
import ServiceCard from '../ServiceCard';

export const SERVICE_CARD = gql(`
  fragment serviceCard on service_Entry {
    uri
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    serviceDetailCategory {
      ...serviceDetailCategory
    }
    pageTitle
  }
`);

const ServiceEntryCard = withFragmentArray(SERVICE_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <ServiceCard data={item} />;
});

export const ServiceListCard = withFragmentArray(SERVICE_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default ServiceEntryCard;
