'use client';

import { gql } from '@/graphql/__generated__';
import { Widget } from '@typeform/embed-react';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';

const TYPEFORM_BLOCK = gql(`
  fragment typeformBlock on blocks_typeform_BlockType {
    genericText
  }
`);

const TypeformBlock = createBlock(TYPEFORM_BLOCK, ({ data, meta }) => {
  if (!data || !data.genericText) return null;

  return (
    <BlockContainer disableContainer meta={meta} marginY="none">
      <Widget id={data.genericText} style={{ height: '100vh', width: '100%' }} />
    </BlockContainer>
  );
});

export default TypeformBlock;
