'use client';

import { UseCategoriesOptions, useCategoryChoice } from '@liquorice/next-search';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';

export interface CategorySelectFieldProps extends UseCategoriesOptions {
  allLabel?: string;
}

const CategorySelectField = ({ allLabel = 'All', ...config }: CategorySelectFieldProps) => {
  const { options, selected, setValue, reset, isSelected, ...choice } = useCategoryChoice(config);

  return (
    <Flex as="ul" aria-label={config.name} spacing="2xs" alignItems="start">
      <Btn
        size="large"
        variant={choice.value === '' ? 'text' : 'nav'}
        color={choice.value === '' ? 'black' : 'grey'}
        onClick={() => reset()}>
        {allLabel}
      </Btn>
      {options?.map((option) => (
        <Btn
          key={option.value}
          size="large"
          variant={isSelected(option.value) ? 'text' : 'nav'}
          color={isSelected(option.value) ? 'black' : 'grey'}
          onClick={() => setValue(option.value)}>
          {option.label}
        </Btn>
      ))}
    </Flex>
  );
};

export default CategorySelectField;
