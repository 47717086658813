'use client';

import { parseCallToAction } from '@/lib/parse/callToAction';
import { useTranslations } from 'next-intl';
import Grid from '../ui/Grid';
import Txt from '../ui/Txt';
import { MenuProps } from './Menu';
import MenuFooterBtn from './Menu.Footer.Btn';
import MenuFooterMenuItem from './Menu.Footer.MenuItem';
import MenuList from './Menu.List';

const MenuFooter = ({ globals, contactNav, socialNav }: MenuProps) => {
  const { contact, location } = globals ?? {};
  const t = useTranslations('actions');

  const locationLink = parseCallToAction(location?.callToAction);
  const contactLink = parseCallToAction(contact?.callToAction);

  const locationChildren = location?.content ?? locationLink?.children;

  return (
    <Grid rowGutter>
      <Grid.Col md={3}>
        <Txt as="p" variant="sm" headingFont uppercase letterSpacing="lede" color="midGrey">
          {t('workWithUs')}
        </Txt>
        <MenuFooterBtn
          startIcon="arrowRight"
          animateIcon
          animateDirection="right"
          {...contactLink}
        />
      </Grid.Col>
      <Grid.Col md={3}>
        <Txt as="p" variant="sm" headingFont uppercase letterSpacing="lede" color="midGrey">
          {t('followUs')}
        </Txt>
        <MenuList>
          {socialNav?.items?.map((item) => {
            return <MenuFooterMenuItem key={item.id} {...item} />;
          })}
        </MenuList>
      </Grid.Col>
      <Grid.Col md={3}>
        <Txt as="p" variant="sm" headingFont uppercase letterSpacing="lede" color="midGrey">
          {t('ringTheDoorbell')}
        </Txt>
        <MenuFooterBtn {...locationLink}>
          <Txt html>{locationChildren}</Txt>
        </MenuFooterBtn>
      </Grid.Col>
      <Grid.Col md={3}>
        <Txt as="p" variant="sm" headingFont uppercase letterSpacing="lede" color="midGrey">
          {t('getInTouch')}
        </Txt>
        <MenuList>
          {contactNav?.items?.map((item) => {
            return <MenuFooterMenuItem key={item.id} {...item} />;
          })}
        </MenuList>
      </Grid.Col>
    </Grid>
  );
};

export default MenuFooter;
