'use client';

import Btn from '@/components/ui/Btn';
import Flex from '@/components/ui/Flex';
import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import useScrollTrigger from '@/lib/hooks/useScrollTrigger';
import { parseCallToActionMultiple } from '@/lib/parse/callToActionMultiple';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { gsap } from 'gsap';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';
import { useEffect } from 'react';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';
import { Clarity } from './Clarity';
import { Direction } from './Direction';
import { serviceTimeline } from './ServiceTimeline';
import * as styles from './TextAnimationBlock.css';
import { Transformation } from './Transformation';

gsap.registerPlugin(DrawSVGPlugin);

export const TEXT_ANIMATION_FRAGMENT = gql(`
  fragment animation on textAnimationItem_Entry {
    content: htmlContentSimple
    animation {
      ...ImageAsset
    }
    callToActionMultiple {
      ...callToActionMultiple
    }
  }
`);

const TEXT_ANIMATION_BLOCK = gql(`
  fragment textAnimationBlock on blocks_textAnimation_BlockType {
    __typename
    id
    textAnimation {
      ...animation
    }
  }
`);

const parseAnimation = createFragmentArrayParser(TEXT_ANIMATION_FRAGMENT, (data) => data);

const TextAnimationBlock = createBlock(TEXT_ANIMATION_BLOCK, ({ data }) => {
  if (!data) return null;

  const animation = parseAnimation(data.textAnimation);
  const { tl, holderRef, gsap, q } = useScrollTrigger();

  const assets = [
    <Clarity key="clarity" className={styles.scale} />,
    <Direction key="direction" className={styles.scale} />,
    <Transformation key="transformation" className={styles.scale} />,
  ];

  useEffect(() => {
    if (!tl.current) {
      serviceTimeline({ tl: tl, holderRef: holderRef, gsap: gsap, q: q });
    }
  });

  return (
    <BlockContainer className={styles.root} ref={holderRef} marginY>
      {animation?.map((content, index: number) => {
        const links = parseCallToActionMultiple(content.callToActionMultiple);

        return (
          <Grid key={'animation-' + index}>
            <Grid.Col md={6}>{assets[index]}</Grid.Col>
            <Grid.Col md={6} cx={{ mY: 'auto' }}>
              <Flex spacing="md" alignItems="start">
                {content.content && <Txt html>{content.content}</Txt>}
                <Flex spacing="xs" alignItems="start">
                  {links?.map((link, i) => {
                    return (
                      link && (
                        <Btn
                          key={i}
                          variant="text"
                          startIcon="arrowRight"
                          animateIcon
                          animateDirection="right"
                          {...link}
                        />
                      )
                    );
                  })}
                </Flex>
              </Flex>
            </Grid.Col>
          </Grid>
        );
      })}
    </BlockContainer>
  );
});

export default TextAnimationBlock;
