import { Color, colorValues } from '@/styles/partials/palette.css';
import { camelCase } from 'change-case-all';

type ColorScheme =
  | {
      __typename:
        | 'colorSwatches'
        | 'pageColor'
        | 'pageTextColor'
        | 'headerColor'
        | 'headerTextColor'
        | 'blockColor'
        | 'blockTextColor';
      label?: string | null;
    }
  | null
  | undefined;

export const parseColorScheme = (data: Maybe<ColorScheme>): Color | undefined => {
  if (!data?.label) return undefined;

  // Convert pretty labels into camel case
  const label = camelCase(data.label);

  return label && label in colorValues ? (label as Color) : undefined;
};
