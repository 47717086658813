'use client';

import Icon from '../Icon';
import * as styles from './Navigation.css';
import { useNavigationActions } from './useNavigation';

const NavigationToggle = ({ open }: { open: boolean }) => {
  const { menu, setMenu, setSearch } = useNavigationActions();

  const handleClick = () => {
    setSearch(false);
    setMenu(!menu);
  };

  return (
    <Icon
      className={styles.toggle}
      name={open ? 'close' : 'logo'}
      aria-label="Toggle menu"
      onClick={handleClick}
    />
  );
};

export default NavigationToggle;
