import { gql } from '@apollo/client';

export const SEARCH_FRAGMENT = gql(`
  fragment search on EntryInterface {
    __typename
    id
    sectionHandle
    uri
    ... on article_Entry {
      pageTitle
      entrySummary
    }
    ... on articleIndex_Entry {
      pageTitle
      entrySummary
    }
    ... on caseStudy_Entry {
      pageTitle
      entrySummary
    }
    ... on caseStudyIndex_Entry {
      pageTitle
      entrySummary
    }
    ... on home_Entry {
      pageTitle
      entrySummary
    }
    ... on overview_Entry {
      pageTitle
      entrySummary
    }
    ... on service_Entry {
      pageTitle
      entrySummary
    }
  }  
`);
