import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import Shim from '../Shim';
import { VideoProps } from '../VideoAsset';
import VideoBackground from '../VideoAsset/VideoBackground';
import * as style from './Card.css';
import { useCard } from './useCard';

export type CardVideoProps = VideoProps & { hover?: boolean };

export const CardVideo = ({ hover = true, ...props }: CardVideoProps) => {
  const { title, videoUrl } = useCard();

  return (
    videoUrl && (
      <Shim className={style.videoWrap} relative ratio="landscapeWide">
        <VideoBackground
          videoUrl={videoUrl}
          title={title ?? ''}
          aria-label={title ?? ''}
          {...mergePropsClassName(props, style.video({ hover }))}
        />
      </Shim>
    )
  );
};
