'use client';

import { gql } from '@/graphql/__generated__';

import { withFragment } from '@liquorice/gql-utils';
import ArticleEntryCard from '../EntryCards/ArticleCard';
import ArticleIndexEntryCard from '../EntryCards/ArticleIndexCard';
import CaseStudyEntryCard from '../EntryCards/CaseStudyCard';
import CaseStudyIndexEntryCard from '../EntryCards/CaseStudyIndexCard';
import HomeEntryCard from '../EntryCards/HomeCard';
import OverviewEntryCard from '../EntryCards/OverviewCard';
import ServiceEntryCard from '../EntryCards/ServiceCard';

export const ENTRY_CARDS_FRAGMENT = gql(`
  fragment entryCards on EntryInterface {
    ...articleCard
    ...articleIndexCard
    ...caseStudyCard
    ...caseStudyIndexCard
    ...homeCard
    ...overviewCard
    ...serviceCard
  }  
`);

const EntryCard = withFragment(ENTRY_CARDS_FRAGMENT, (data) => {
  if (!data) return null;

  if (data.__typename === 'article_Entry') {
    return <ArticleEntryCard data={data} />;
  }
  if (data.__typename === 'articleIndex_Entry') {
    return <ArticleIndexEntryCard data={data} />;
  }
  if (data.__typename === 'caseStudy_Entry') {
    return <CaseStudyEntryCard data={data} />;
  }
  if (data.__typename === 'caseStudyIndex_Entry') {
    return <CaseStudyIndexEntryCard data={data} />;
  }
  if (data.__typename === 'home_Entry') {
    return <HomeEntryCard data={data} />;
  }
  if (data.__typename === 'overview_Entry') {
    return <OverviewEntryCard data={data} />;
  }
  if (data.__typename === 'service_Entry') {
    return <ServiceEntryCard data={data} />;
  }
});

export default EntryCard;
