'use client';

import Grid from '@/components/ui/Grid';
import Txt from '@/components/ui/Txt';
import { gql } from '@/graphql/__generated__';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';

const TEXT_COLUMNS_BLOCK = gql(`
  fragment textColumnsBlock on blocks_textColumns_BlockType {
    __typename
    id
    columnText1
    columnText2
    blockTheme {
      ...blockTheme
    }
  }
`);

const TextColumnsBlock = createBlock(TEXT_COLUMNS_BLOCK, ({ data, meta }) => {
  return (
    <BlockContainer meta={meta} marginY>
      <Grid>
        <Grid.Col md={6}>
          <Txt html>{data.columnText1}</Txt>
        </Grid.Col>
        <Grid.Col md={6}>
          <Txt html>{data.columnText2}</Txt>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default TextColumnsBlock;
