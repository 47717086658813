'use client';

import MenuFooter from '@/components/Menu/Menu.Footer';
import MenuMain from '@/components/Menu/Menu.Main';
import { AppGlobals } from '@/lib/fetch/globals';
import { NavMenu } from '@/lib/parse/nav';
import { Drawer } from '@mui/material';
import EntryTheme from '../EntryTheme';
import { useNavigationActions } from '../Navigation';
import NavigationBar from '../Navigation/Navigation.Bar';
import Container from '../ui/Container';
import * as styles from './Menu.css';

export type MenuProps = {
  coreNav?: NavMenu;
  servicesNav?: NavMenu;
  socialNav?: NavMenu;
  contactNav?: NavMenu;
  globals?: AppGlobals | null;
};

const Menu = (props: MenuProps) => {
  const menuTheme = useNavigationActions((s) => s.menuTheme);
  const menu = useNavigationActions((s) => s.menu);
  const setMenu = useNavigationActions((s) => s.setMenu);

  return (
    <Drawer
      open={menu}
      anchor="top"
      elevation={0}
      hideBackdrop
      transitionDuration={{ enter: 500, exit: 500 }}
      onClose={() => setMenu(false)}
      PaperProps={{ className: styles.root }}>
      <EntryTheme
        cx={{
          transition: 'colors',
        }}
        theme={{
          headerColor: menuTheme,
        }}>
        <Container
          maxWidth="none"
          cx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'between',
            rowGap: 'xl',
            pY: 'md',
            height: 'screen',
          }}>
          <NavigationBar navToggle />
          <MenuMain {...props} />
          <MenuFooter {...props} />
        </Container>
      </EntryTheme>
    </Drawer>
  );
};

export default Menu;
