'use client';

import Card, { CardProps } from '@/components/Card';
import { ArticleCardFragment } from '@/graphql/__generated__/graphql';
import { ARTICLE_CATEGORY_FRAGMENT } from '@/lib/fetch/categories';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable, parseUri } from '@liquorice/utils';

type ArticleCardProps = { data: ArticleCardFragment } & Omit<CardProps, 'item'>;

const articleCategoryParser = createFragmentParser(ARTICLE_CATEGORY_FRAGMENT, (data) => data);

const ArticleCard = ({ data, ...props }: ArticleCardProps) => {
  const { postDate, entryImage, pageTitle, uri } = data;
  const articleCategory = excludeEmptyTypedElements(data.articleCategory);
  const categories = articleCategoryParser(firstNonNullable(articleCategory));
  const href = parseUri(uri);

  return (
    <Card
      padding="md"
      elevation={false}
      fullHeight
      item={{
        title: pageTitle,
        date: postDate,
        image: entryImage,
        description: categories?.title,
        href,
      }}
      {...props}>
      <Card.Image ratio="LANDSCAPE_WIDE" />
      <Card.Body>
        <Card.Description variant="sm" uppercase headingFont letterSpacing="lede" />
        <Card.Title />
        <Card.Date variant="sm" />
      </Card.Body>
    </Card>
  );
};

export default ArticleCard;
