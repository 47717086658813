import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import ArticleIndexCard from '../ArticleIndexCard';
import { ListCardRoot } from '../ListCard';

export const ARTICLE_INDEX_CARD = gql(`
  fragment articleIndexCard on articleIndex_Entry {
    uri
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    pageTitle
  }
`);

const ArticleIndexEntryCard = withFragmentArray(ARTICLE_INDEX_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <ArticleIndexCard data={item} />;
});

export const ArticleIndexListCard = withFragmentArray(ARTICLE_INDEX_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default ArticleIndexEntryCard;
