import { gql } from '../__generated__';

export const CALL_TO_ACTION_URL_FRAGMENT = gql(`
  fragment callToActionUrl on callToAction_Url_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_ENTRY_FRAGMENT = gql(`
  fragment callToActionEntry on callToAction_Entry_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_CUSTOM_FRAGMENT = gql(`
  fragment callToActionCustom on callToAction_Custom_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_EMAIL_FRAGMENT = gql(`
  fragment callToActionEmail on callToAction_Email_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_PHONE_FRAGMENT = gql(`
  fragment callToActionPhone on callToAction_Phone_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const CALL_TO_ACTION_FRAGMENT = gql(`
  fragment callToAction on HyperLinkInterface {
    ...callToActionUrl
    ...callToActionEntry
    ...callToActionCustom
    ...callToActionEmail
    ...callToActionPhone
  }
`);
