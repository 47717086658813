import { gql } from '@/graphql/__generated__';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable, toStringOrNull } from '@liquorice/utils';
import { parseColorScheme } from './colorScheme';

export const ENTRYTHEME_BLOCK = gql(`
  fragment entryTheme on entryTheme_Entry {
    pageColor {
      label
    }
    customPageColor
    pageTextColor {
      label
    }
    headerColor {
      label
    }
    customHeaderColor
    headerTextColor {
      label
    }
  }
`);

export const BLOCKTHEME_BLOCK = gql(`
  fragment blockTheme on blockTheme_Entry {
    blockColor {
      label
    }
    customBlockColor
    blockTextColor {
      label
    }
  }
`);

export const parseEntryTheme = createFragmentArrayParser(ENTRYTHEME_BLOCK, (data) => {
  const item = firstNonNullable(data);

  const headerColor = parseColorScheme(item?.headerColor);
  const headerTextColor = parseColorScheme(item?.headerTextColor);

  const customHeaderColor = toStringOrNull(item?.customHeaderColor);

  const pageColor = parseColorScheme(item?.pageColor);
  const pageTextColor = parseColorScheme(item?.pageTextColor);

  const customPageColor = toStringOrNull(item?.customPageColor);

  return {
    pageColor,
    customPageColor,
    pageTextColor,
    headerColor,
    customHeaderColor,
    headerTextColor,
  };
});

export type EntryThemeProps = ReturnType<typeof parseEntryTheme>;

export const parseBlockTheme = createFragmentArrayParser(BLOCKTHEME_BLOCK, (data) => {
  const item = firstNonNullable(data);

  const blockColor = parseColorScheme(item?.blockColor);
  const blockTextColor = parseColorScheme(item?.blockTextColor);

  const customBlockColor = toStringOrNull(item?.customBlockColor);

  return {
    blockColor,
    customBlockColor,
    blockTextColor,
  };
});

export type BlockThemeProps = ReturnType<typeof parseBlockTheme>;
