'use client';

import { CaseStudyCardFragment } from '@/graphql/__generated__/graphql';
import { useFragment } from '@apollo/client';
import React from 'react';
import CaseStudyCard from '../CaseStudyCard';
import { CASE_STUDY_CARD_FRAGMENT } from '../EntryCards';
import { ResultProps } from '../Results/Result';

export default function CaseStudyResult({ id }: Omit<ResultProps, 'typeName'>) {
  const { data } = useFragment({
    fragment: CASE_STUDY_CARD_FRAGMENT,
    fragmentName: 'caseStudyCard',
    from: { __typename: 'caseStudy_Entry', id },
  });

  const [entry, setEntry] = React.useState<CaseStudyCardFragment>();

  React.useEffect(() => {
    if (data) setEntry(data as CaseStudyCardFragment);
  }, [data]);

  if (!entry) return null;

  return <CaseStudyCard data={entry} />;
}
