'use client';

import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import Shim, { ShimProps } from '../Shim';
import * as styles from './Navigation.css';
import { useNavigationActions } from './useNavigation';

export default function NavigationShim(props: ShimProps) {
  const { height, fallbackHeight } = useNavigationActions();

  return (
    <Shim
      relative={false}
      {...mergePropsClassName(props, styles.shim)}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}
    />
  );
}
