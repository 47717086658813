'use client';

import { EntryThemeProps } from '@/lib/parse/theme';
import { useTranslations } from 'next-intl';
import Btn from '../ui/Btn';
import Flex from '../ui/Flex';
import Grid, { GridProps } from '../ui/Grid';
import Txt from '../ui/Txt';

type CallToActionProps = GridProps<
  'div',
  {
    heading?: string | null;
    entryTheme?: EntryThemeProps;
  }
>;

export default function CallToAction({ heading, entryTheme, ...props }: CallToActionProps) {
  const t = useTranslations('actions');

  const textColor = entryTheme?.headerTextColor ?? entryTheme?.pageTextColor;
  const isLight = textColor !== 'black' && textColor !== undefined;

  return (
    <Grid {...props}>
      <Grid.Col>
        <Txt as="h4" variant="h2" autoMargin cx={{ textAlign: 'center' }}>
          {heading ?? `${t('gotAProjectInMind')}?`}
        </Txt>
      </Grid.Col>
      <Grid.Col>
        <Flex alignItems="center">
          <Btn
            uppercase
            size="large"
            variant="outlined"
            color={isLight ? 'white' : 'black'}
            href="mailto:enquiries@liquorice.com.au">
            {t('getInTouch')}
          </Btn>
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
