'use client';

import { EntryThemeProps } from '@/lib/parse/theme';
import { mergePropsClassName } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import useResizeObserver from 'use-resize-observer';
import EntryTheme from '../EntryTheme';
import HideOnScroll from '../HideOnScroll';
import Box, { BoxProps } from '../ui/Box';
import Container from '../ui/Container';
import * as styles from './Navigation.css';
import { useNavigationActions } from './useNavigation';

export type NavigationInnerProps = BoxProps<
  'div',
  {
    entryTheme?: EntryThemeProps;
  }
>;

export default function NavigationInner({ children, entryTheme, ...props }: NavigationInnerProps) {
  const { setHeight, fallbackHeight } = useNavigationActions();

  const { ref, height = fallbackHeight } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setHeight(height);
    },
  });

  return (
    <HideOnScroll>
      <Box
        ref={ref}
        as="nav"
        style={assignInlineVars({
          [styles.navbarHeight]: `${height ?? fallbackHeight}px`,
        })}
        {...mergePropsClassName(props, styles.root)}>
        <EntryTheme theme={entryTheme}>
          <Container maxWidth="none" cx={{ pY: 'md' }}>
            {children}
          </Container>
        </EntryTheme>
      </Box>
    </HideOnScroll>
  );
}
