'use client';

import Grid from '@/components/ui/Grid';
// import { gsap } from 'gsap';
// import { ScrollTrigger } from 'gsap/ScrollTrigger';
// import { SplitText } from 'gsap/SplitText';
// import { useLayoutEffect } from 'react';
import Container from '../ui/Container';
import Txt from '../ui/Txt';

// gsap.registerPlugin(ScrollTrigger, SplitText);

const Heading = ({ heading }: { heading?: string | null }) => {
  // useLayoutEffect(() => {
  //   const split = new SplitText('h1', { type: 'lines' });
  //   gsap.to('.heading', { autoAlpha: 1 });
  //   gsap.to(split.lines, {
  //     autoAlpha: 1,
  //     y: 0,
  //     ease: 'power1.inOut',
  //     duration: 0.3,
  //     stagger: 0.025,
  //   });
  // });

  if (!heading) return null;

  return (
    <Container>
      <Grid cx={{ mT: '6xl' }}>
        <Grid.Col>
          <Txt html variant="h1" className="heading">
            {heading}
          </Txt>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default Heading;
