'use client';

import Box from '@/components/ui/Box';
import Btn from '@/components/ui/Btn';
import Grid from '@/components/ui/Grid';
import VideoAsset from '@/components/VideoAsset';
import VideoBackground from '@/components/VideoAsset/VideoBackground';
import { gql } from '@/graphql/__generated__';
import useWindowSize from '@/lib/hooks/useWindowSize';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import dynamic from 'next/dynamic';
import { useEffect, useRef, useState } from 'react';
import { BlockContainer } from '../../BlockContainer';
import { createBlock } from '../../createBlock';
import * as style from './VideoHeroBlock.css';

// Dynamic Imports for Performance Improvements
const DynamicPopupVideo = dynamic(() => import('@/components/PopupVideo'));

const VIDEO_HERO_BLOCK = gql(`
  fragment videoHeroBlock on blocks_videoHero_BlockType {
    __typename
    id
    popupVideoUrl
    clippedVideoUrl
  }
`);

const VideoHeroBlock = createBlock(VIDEO_HERO_BLOCK, ({ data, meta }) => {
  const { isMobile, isDesktop } = useWindowSize();
  const [isOpen, setOpen] = useState(false);

  const fadeIn = useRef(gsap.timeline());
  const infiniteRotation = useRef(gsap.timeline());
  const scroll = useRef(gsap.timeline());
  const backgroundVideo = useRef<HTMLDivElement>(null);
  const desktopVideo = useRef<HTMLDivElement>(null);

  const mask = useRef<HTMLDivElement>(null);
  const button = useRef<HTMLDivElement>(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    fadeIn.current = gsap.timeline({ defaults: { ease: 'power1.inOut' } });
    infiniteRotation.current = gsap.timeline({
      defaults: { ease: 'none', duration: 1, repeat: -1, repeatDelay: 5 },
    });
    scroll.current = gsap.timeline({
      defaults: { autoAlpha: 1, ease: 'power1.inOut' },
      scrollTrigger: {
        trigger: mask.current,
        scrub: 0.25,
        start: 'top center',
        end: '40% center',
      },
    });

    fadeIn.current.to(mask.current, {
      autoAlpha: 1,
      y: 0,
    });

    infiniteRotation.current
      .to(mask.current, {
        clipPath: 'polygon(50% 2%, 90% 25%, 90% 75%, 50% 98%, 10% 75%, 10% 25%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(55% 7%, 96% 30%, 85% 83%, 45% 93%, 4% 70%, 15% 17%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(58% 13%, 99% 37%, 78% 90%, 43% 87%, 1% 63%, 22% 10%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(57% 20%, 100% 45%, 71% 96%, 43% 80%, 0% 55%, 29% 4%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(78% 25%, 99% 54%, 63% 99%, 22% 75%, 1% 46%, 37% 1%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(85% 24%, 96% 64%, 56% 100%, 15% 76%, 4% 36%, 44% 1%)',
      })
      .to(mask.current, {
        clipPath: 'polygon(90% 25%, 90% 75%, 50% 98%, 10% 75%, 10% 25%, 50% 2%)',
      });

    scroll.current.fromTo(button.current, { autoAlpha: 0 }, { autoAlpha: 1 });

    return () => {
      fadeIn.current.kill();
      infiniteRotation.current.kill();
      scroll.current.kill();
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      infiniteRotation.current.pause();
      if (isDesktop) {
        scroll.current.kill();
        fadeIn.current
          .to(backgroundVideo.current, {
            autoAlpha: 0,
          })
          .to(
            button.current,
            {
              autoAlpha: 0,
            },
            '<'
          )
          .to(
            mask.current,
            {
              scale: 3,
              duration: 1,
            },
            '<'
          )
          .to(desktopVideo.current, {
            autoAlpha: 1,
          })
          .to(mask.current, {
            autoAlpha: 0,
          });
      }
    } else {
      infiniteRotation.current.resume();
    }
  }, [isOpen, isDesktop]);

  if (!data) return null;

  return (
    <BlockContainer meta={meta} marginY>
      <Grid>
        <Box className={style.gridInner}>
          <div ref={mask} className={style.wrapper}>
            <div ref={button} className={style.button} onClick={() => setOpen(!isOpen)}>
              <Btn variant="filled" color="black" uppercase startIcon="play">
                play
              </Btn>
            </div>
            <div ref={backgroundVideo} className={style.backgroundVideoWrapper}>
              <VideoBackground
                videoUrl={data.clippedVideoUrl}
                playing={true}
                controls={false}
                muted={true}
                loop={true}
              />
            </div>
          </div>
          {isDesktop && (
            <Box className={style.desktopVideoWrapper}>
              <div ref={desktopVideo} className={style.desktopVideo}>
                <VideoAsset
                  videoUrl={data.popupVideoUrl}
                  playing={isOpen}
                  controls={true}
                  muted={true}
                  loop={true}
                />
              </div>
            </Box>
          )}
        </Box>
      </Grid>
      {isMobile && (
        <DynamicPopupVideo isOpen={isOpen} setOpen={setOpen} video={data.popupVideoUrl} />
      )}
    </BlockContainer>
  );
});

export default VideoHeroBlock;
