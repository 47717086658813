'use client';

import Icon from '../Icon';
import * as styles from './Navigation.css';
import { useNavigationActions } from './useNavigation';

const NavigationSearch = ({ open }: { open: boolean }) => {
  const { search, setSearch, setMenu } = useNavigationActions();

  const handleClick = () => {
    setMenu(false);
    setSearch(!search);
  };

  return (
    <Icon
      className={styles.search}
      name={open ? 'close' : 'search'}
      aria-label="Toggle search"
      onClick={handleClick}
    />
  );
};

export default NavigationSearch;
