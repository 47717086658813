import { EntryThemeProps } from '@/lib/parse/theme';
import { vars } from '@/styles';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { toStringOrNull } from '@liquorice/utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import Box, { BoxProps } from '../ui/Box';
import * as styles from './EntryTheme.css';

export type ThemeProps<C extends React.ElementType = 'div'> = BoxProps<
  C,
  {
    theme?: Partial<EntryThemeProps>;
  }
>;

export default function EntryTheme<C extends React.ElementType = 'div'>({
  children,
  theme,
  ...props
}: ThemeProps<C>) {
  const headerBg = toStringOrNull(theme?.customHeaderColor);
  const pageBg = toStringOrNull(theme?.customPageColor);

  if (!theme) return children;

  const header = headerBg
    ? headerBg
    : theme.headerColor
    ? vars.palette.colors[theme.headerColor]
    : undefined;
  const page = pageBg ? pageBg : theme.pageColor ? vars.palette.colors[theme.pageColor] : undefined;

  return (
    <Box
      textColor={theme.headerTextColor ?? theme.pageTextColor}
      style={{
        ...assignInlineVars({
          [styles.bg]: `${header ?? page}`,
        }),
      }}
      {...mergePropsClassName(props, styles.root)}>
      {children}
    </Box>
  );
}
