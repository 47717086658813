'use client';

import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { useSearchInputs } from '@liquorice/next-search';
import IconButton from '../IconButton';
import Box from '../ui/Box';
import Flex from '../ui/Flex';
import * as styles from './Search.css';

const SearchInput = () => {
  const { inputProps, buttonProps } = useSearchInputs();

  return (
    <Flex direction="row" alignItems="center" className={styles.inputWrapper}>
      <Box as="input" className={styles.input} {...inputProps} />
      <IconButton icon="search" size="large" {...mergePropsClassName(buttonProps, styles.submit)} />
    </Flex>
  );
};

export default SearchInput;
