import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { OverrideProps } from '@liquorice/utils';
import React from 'react';
import NextAnchor, { NextAnchorPropsWithRef } from '../ui/NextAnchor';
import { Polymorphic } from '../ui/Polymorphic';
import * as style from './Card.css';

type CardInnerBaseProps = NoProps;

export type CardInnerProps<
  C extends React.ElementType = 'div',
  P = NoProps
> = Polymorphic.PropsWithRef<C, CardInnerBaseProps & P>;

export interface CardInnerComponent<P = NoProps> {
  (props: OverrideProps<CardInnerBaseProps & NextAnchorPropsWithRef, P>): React.ReactNode | null;
  <C extends React.ElementType = 'div'>(
    props: Polymorphic.PropsWithRef<C, OverrideProps<CardInnerBaseProps, P>>
  ): React.ReactNode | null;
}

/**
 * Wrapper for all Card content
 * Can be given an `href` property to enable link functionality
 */
export const CardInner: CardInnerComponent = React.forwardRef(function CardInner<
  C extends React.ElementType
>({ as, ...props }: CardInnerProps<C>, ref?: Polymorphic.Ref<C>) {
  const isLink = as === 'a' || 'href' in props;

  /**
   * Force {@link NextAnchor} as anchor component
   */
  const Component = isLink ? NextAnchor : as || 'div';
  return <Component ref={ref} {...mergePropsClassName(props, style.inner)} />;
});
