import Card, { CardProps } from '@/components/Card';
import { HomeCardFragment } from '@/graphql/__generated__/graphql';
import { parseUri } from '@liquorice/utils';

type HomeCardProps = { data: HomeCardFragment } & Omit<CardProps, 'item'>;

const HomeCard = ({ data, ...props }: HomeCardProps) => {
  const { entryImage, pageTitle, uri } = data;
  const href = parseUri(uri);

  return (
    <Card
      cx={{ bgcolor: 'candy' }}
      padding="lg"
      elevation={false}
      rounded
      fullHeight
      item={{
        title: pageTitle,
        image: entryImage,
        href,
      }}
      {...props}
    />
  );
};

export default HomeCard;
