import classNames from 'classnames';
import { SVGProps } from 'react';

export const Transformation = ({ className }: SVGProps<SVGElement>) => {
  const holderClasses = classNames('transformation', className);

  return (
    <svg
      id="Purple"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 935 935"
      className={holderClasses}>
      <polygon
        className="transformation-shape-left"
        fill="#d39aff"
        points="307.32 559.98 307.32 375.02 467.5 282.54 467.5 467.5 307.32 559.98"
      />
      <polyline
        className="transformation-shape-bottom"
        fill="#d39aff"
        points="627.68 559.98 467.5 467.5 307.32 559.98 467.5 652.46 627.68 559.98"
      />
      <polygon
        className="transformation-shape-right"
        fill="#d39aff"
        points="627.68 559.98 627.68 375.02 467.5 282.54 467.5 467.5 627.68 559.98"
      />
      <path
        className="transformation-bottom-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        d="m582.47,662.52s-55.56-6.24-61.64-11.06c0,0,7.56-17.48-2.96-33.05-8.12-12.01-22.08-23.4-29.61-16.87-9.75,8.45,19.72,23.75,5.96,36.69-3.1,2.92-66.85-43.06-75.46-32.61-8.6,10.45,32.35,33.54,36.66,39.4,0,0-22.69-16.06-30.62-16.46-4.83-.24-7.07,5.21-5.02,10.79,4.61,12.59,27.06,24.77,30.26,28.09-4.77-2.6-13.38-8.42-19.01-10.95s-10.99,3.12-7.11,11.5c4.02,8.67,17.5,16.07,22.11,19.33-8.54-3.61-14.02-10.35-18.48-10.54-3.35-.14-6.4,2.89-5.16,8.68,2.55,11.91,28.06,22.41,45.81,29.66,40.61,16.58,109.26,23.3,109.26,23.3"
      />
      <path
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        d="m692.51,303.93s-61.56,34.41-68.86,37.05c-7.3,2.64-30.2-17.46-40.24-9.35-10.04,8.11,12.12,22.75,10.83,26.8-1.29,4.06-64.24,30.07-59.88,42.89,4.36,12.82,45.24-6.32,45.24-6.32,0,0-46.14,20.06-40.6,32.27,5.55,12.2,59.24-14.4,59.24-14.4,0,0-36.88,19.05-32.41,28.42,4.46,9.37,48.17-13.95,48.17-13.95,0,0-20.49,12.84-18.3,20,2.19,7.16,28.54-2.36,48.66-10.82,20.12-8.45,104.62-47.63,104.62-47.63"
      />
      <line
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        x1="669.13"
        y1="355.03"
        x2="653.41"
        y2="325.51"
      />
      <line
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        x1="699.34"
        y1="411.77"
        x2="686.56"
        y2="387.77"
      />
      <line
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        x1="682.06"
        y1="342.4"
        x2="668.63"
        y2="317.18"
      />
      <line
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        x1="715.13"
        y1="404.52"
        x2="702.89"
        y2="375.9"
      />
      <ellipse
        className="transformation-right-arm-watch"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        cx="684.83"
        cy="364.88"
        rx="24.65"
        ry="18.4"
        transform="translate(91.78 865.31) rotate(-68.21)"
      />
      <path
        className="transformation-right-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        d="m686.5,373.98l-1.41-7.7c-.17-.91-.04-1.85.37-2.67l5.98-12.14"
      />
      <path
        className="transformation-top-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        d="m355.2,345.79c.14-4.93,1.86-16.95,13.44-28.49"
      />
      <path
        className="transformation-top-arm"
        fill="none"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4px"
        d="m305.99,467.18c-11.36-17.7-27.93-52.5-27.01-103.9.85-47.25,16.74-82.05,55.26-105.53,20.62-12.58,45.77-19.45,69.4-15.17,13.78,2.5,25.24,7.19,36.28,18.1,12.5,12.35,25.4,37.4,19.75,42.76-4.15,3.94-8.84,1.01-14.33-4.18,0,0-6.36-9.12-13.4-16.74-4.47-4.83-8.95-8.48-10.22-9.51,3.21,2.58,7.16,6.14,10.22,9.51,10.39,11.43,31.33,39.22,20.46,45.4-4.96,2.82-16.37-4.65-20.15-11.2,0,0-5.87-10.25-21.6-24.03,15.9,13.72,32.13,34.12,23.14,39.98-6.29,4.1-17.43-7.42-23.06-11.75,0,0-3.88-5.1-12.91-11.54,9.83,7.03,18.88,16.07,17.19,23.22-2.95,12.4-27.09-8.69-39.76-13.77-9.91-3.97-19.96.53-19.96.53"
      />
    </svg>
  );
};
