import classNames from 'classnames';
import { SVGProps } from 'react';

export const Clarity = ({ className }: SVGProps<SVGElement>) => {
  const holderClasses = classNames('clarity', className);

  return (
    <svg
      id="Green"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 935 935"
      className={holderClasses}>
      <defs>
        <filter id="gfgid">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" />
        </filter>
        <mask id="mask-inner">
          <ellipse
            className="mask-inner"
            cx="566.97"
            cy="385.83"
            rx="94.77"
            ry="105.59"
            transform="translate(-15.35 748.08) rotate(-66.12)"
            fill="white"
            fillOpacity="1"
          />
        </mask>
      </defs>
      <g>
        <polygon
          className="clarity-polygon clarity-polygon-outer"
          fill="#E4FBB3"
          filter="url(#gfgid)"
          points="627.68 559.98 627.68 375.02 467.5 282.54 307.32 375.02 307.32 559.98 467.5 652.46 627.68 559.98"
        />
        <polygon
          mask="url(#mask-inner)"
          style={{ visibility: 'hidden' }}
          className="clarity-polygon clarity-polygon-inner"
          fill="#d2f980"
          points="627.68 559.98 627.68 375.02 467.5 282.54 307.32 375.02 307.32 559.98 467.5 652.46 627.68 559.98"
        />
        <g className="clarity-magni" style={{ visibility: 'hidden' }}>
          <ellipse
            className="clarity-magni-ellipse"
            strokeMiterlimit="10"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            cx="566.97"
            cy="385.83"
            rx="94.77"
            ry="105.59"
            transform="translate(-15.35 748.08) rotate(-66.12)"
          />
          <path
            className="clarity-magni-handle"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            d="m655.92,474.78c6.84-6.84,17.92-6.84,24.76,0l55.56,55.56c6.84,6.84,6.84,17.92,0,24.76h0c-6.84,6.84-17.92,6.84-24.76,0l-55.56-55.56c-6.84-6.84-6.84-17.92,0-24.76l-14.28-14.28"
          />
          <path
            className="clarity-magni-sheen"
            strokeMiterlimit="10"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            strokeLinecap="round"
            d="m645.32,380.52s-8.68-43.77-50.14-59.58c-33.56-12.79-37.89,21.76-34.09,37.05,3.81,15.29,16.96,28.28,26,26.75,24.78-4.21-4.49-71.9-43.75-65.83-26.92,4.16-34.53,26.38-34.53,26.38"
          />
          <line
            className="clarity-magni-line"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            x1="655.03"
            y1="301.91"
            x2="701.03"
            y2="263.62"
          />
          <line
            className="clarity-magni-line"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            x1="645.72"
            y1="290.4"
            x2="654.89"
            y2="261.94"
          />
          <line
            className="clarity-magni-line"
            strokeLinejoin="round"
            strokeLinecap="round"
            fill="none"
            stroke="black"
            strokeWidth="4px"
            x1="666.79"
            y1="320.03"
            x2="712.3"
            y2="305.28"
          />
        </g>
      </g>
    </svg>
  );
};
