import { FieldValues } from 'react-hook-form';
import * as z from 'zod';

export const validationSchema = z.object({
  email: z.string().min(1, { message: 'Email is required' }).email({
    message: 'Invalid email address',
  }),
  name: z.string().min(1, { message: 'Name is required' }).max(40, {
    message: 'Name is too many characters',
  }),
});

export type ValidationSchema = z.infer<typeof validationSchema> | FieldValues;
