import Grid from '../ui/Grid';
import { MenuProps } from './Menu';
import MenuItem from './Menu.Item';
import MenuList from './Menu.List';
import * as styles from './Menu.css';

const MenuMain = ({ coreNav, servicesNav }: MenuProps) => {
  return (
    <Grid rowGutter>
      <Grid.Col md={6}>
        <MenuList>
          {coreNav?.items?.map((item) => {
            return (
              <MenuItem
                key={item.id}
                {...item}
                TxtProps={{
                  className: styles.mainItem,
                }}
              />
            );
          })}
        </MenuList>
      </Grid.Col>
      <Grid.Col md={6}>
        <MenuList>
          {servicesNav?.items?.map((item, i) => {
            return (
              <MenuItem
                key={item.id}
                {...item}
                TxtProps={{
                  variant: i === 0 ? 'navH1' : 'h4',
                  className: i === 0 ? styles.mainItem : styles.subItem,
                }}
              />
            );
          })}
        </MenuList>
      </Grid.Col>
    </Grid>
  );
};

export default MenuMain;
