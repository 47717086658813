'use client';

import { IMG_RATIO } from '@/styles';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import Shim, { ShimProps } from '../Shim';
import { VideoPlayer } from './VideoPlayer';

export type VideoProps = ShimProps<
  'div',
  {
    videoUrl?: Maybe<string>;
    controls?: boolean;
    playing?: boolean;
    loop?: boolean;
    muted?: boolean;
  }
>;
const VideoAsset = ({
  videoUrl,
  controls = true,
  playing = false,
  loop = false,
  muted = false,
  ...props
}: VideoProps) => {
  const [ratio, setRatio] = useState<string>(`${IMG_RATIO['VIDEO'] * 100}%`);

  const handleOnReady = (player: ReactPlayer) => {
    const maybeInternalPlayer = player.getInternalPlayer();
    if (!maybeInternalPlayer) return;
    Promise.all([maybeInternalPlayer.getVideoWidth(), maybeInternalPlayer.getVideoHeight()]).then(
      function ([width, height]) {
        const ratio = `${(height / width) * 100}%`;
        setRatio(ratio);
      }
    );
  };

  return videoUrl ? (
    <Shim style={{ paddingTop: ratio }} {...props}>
      <VideoPlayer
        {...{
          onReady: handleOnReady,
          url: videoUrl,
          controls,
          playing,
          loop,
          muted,
        }}
      />
    </Shim>
  ) : (
    <></>
  );
};

export default VideoAsset;
