'use client';

import { BoxProps } from '@/components/ui/Box';
import Container, { ContainerProps } from '@/components/ui/Container';
import { BlockMeta } from '@/lib/parse/blocks';
import { BlockThemeProps } from '@/lib/parse/theme';
import { useForwardedRef } from '@/lib/utils/useForwardedRef';
import { Sprinkles } from '@/styles';
import { mergePropsClassName } from '@liquorice/allsorts-craftcms-nextjs';
import { slugify } from '@liquorice/utils';
import classNames from 'classnames';
import React from 'react';
import BlockTheme from '../BlockTheme';
import Grid, { GridColProps, GridProps } from '../ui/Grid';
import * as style from './BlockContainer.css';

export type BlockContainerProps = BoxProps<
  'section',
  style.BlockContainerVariants & {
    children?: React.ReactNode;
    disableContainer?: boolean;
    ContainerProps?: ContainerProps;
    GridProps?: GridProps;
    GridColProps?: GridColProps;
    maxWidth?: ContainerProps['maxWidth'];
    meta?: BlockMeta;
    paddingY?: Sprinkles['paddingY'] | true;
    marginY?: Sprinkles['marginY'] | true;
    overflow?: boolean;
    blockTheme?: Partial<BlockThemeProps>;
  }
>;

export const BlockContainer = React.forwardRef(function BlockContainer(
  {
    children,
    className: classNameProvided,
    disableContainer,
    marginY,
    paddingY,
    overflow,
    cx,
    maxWidth,
    ContainerProps,
    GridProps,
    GridColProps,
    meta,
    blockTheme,
    ...props
  }: BlockContainerProps,
  forwardedRef?: React.ForwardedRef<HTMLElement>
) {
  const ref = useForwardedRef(forwardedRef);
  const isLast = !!meta?.last;
  const isFirst = !!meta?.first;

  let inner = children;

  const customGridColProps = {
    ...GridColProps,
  };

  if (!disableContainer)
    inner = (
      <Container
        {...{
          maxWidth,
          ...mergePropsClassName(ContainerProps, style.container({ overflow })),
        }}>
        <Grid {...mergePropsClassName(GridProps, style.grid)}>
          <Grid.Col {...mergePropsClassName(customGridColProps, style.gridCol)}>
            {children}
          </Grid.Col>
        </Grid>
      </Container>
    );

  const className = classNames(
    classNameProvided,
    style.root({
      last: isLast,
      first: isFirst,
    })
  );

  const blockSpace = '7xl';

  const usesMargin = marginY === true;
  const usesPadding = paddingY === true;

  let paddingTop = usesPadding ? blockSpace : paddingY;
  let paddingBottom = usesPadding ? blockSpace : paddingY;

  let blockTopMargin = usesMargin ? blockSpace : marginY;
  let blockBottomMargin = usesMargin ? blockSpace : marginY;

  if (!!blockTheme?.blockColor || !!blockTheme?.customBlockColor) paddingTop = blockSpace;
  if (!!blockTheme?.blockColor || !!blockTheme?.customBlockColor) paddingBottom = blockSpace;

  if (usesPadding) blockBottomMargin = 'none';
  if (usesPadding) blockTopMargin = 'none';
  if (isLast && usesPadding) blockBottomMargin = 'none';

  return (
    <BlockTheme
      theme={blockTheme ?? {}}
      className={className}
      id={meta?.anchor ? slugify(meta.anchor) : undefined}
      data-block={`${meta?.typename.split('_')[1]}-${meta?.index}`}
      ref={ref}
      {...props}
      cx={{
        mT: blockTopMargin,
        mB: blockBottomMargin,
        pT: paddingTop,
        pB: paddingBottom,
        ...cx,
      }}>
      {inner}
    </BlockTheme>
  );
});
