import Card, { CardProps } from '@/components/Card';
import { OverviewCardFragment } from '@/graphql/__generated__/graphql';
import { parseUri } from '@liquorice/utils';

type OverviewCardProps = { data: OverviewCardFragment } & Omit<CardProps, 'item'>;

const OverviewCard = ({ data, ...props }: OverviewCardProps) => {
  const { entryImage, pageTitle, uri } = data;
  const href = parseUri(uri);

  return (
    <Card
      cx={{ bgcolor: 'candy' }}
      padding="lg"
      elevation={false}
      rounded
      fullHeight
      item={{
        title: pageTitle,
        image: entryImage,
        href,
      }}
      {...props}
    />
  );
};

export default OverviewCard;
