export type serviceTimelineType = {
  tl: any;
  holderRef: any;
  gsap: GSAP;
  q: any;
};

export const serviceTimeline = ({ tl, holderRef, gsap, q }: serviceTimelineType) => {
  gsap.set('.transformation-top-arm', { x: -30 });
  gsap.set(['.transformation-right-arm', '.transformation-right-arm-watch'], { x: 30 });
  gsap.set('.transformation-bottom-arm', { x: 30, y: 40 });

  const clarity = (tl.current = gsap
    .timeline({ defaults: { duration: 0.75 } })
    .to(
      q(['.clarity-magni']),
      {
        autoAlpha: 1,
      },
      '<'
    )
    .from(
      q('.clarity-magni-ellipse'),
      {
        drawSVG: '0%',
      },
      '0.1'
    )
    .from(
      q('.clarity-magni-sheen'),
      {
        drawSVG: '0%',
      },
      '0.1'
    )
    .from(
      q('.clarity-magni-handle'),
      {
        drawSVG: '0%',
      },
      '0.1'
    )
    .from(
      q('.clarity-polygon-inner'),
      {
        autoAlpha: 0,
      },
      '<'
    )
    .from(
      q('.clarity-magni-line'),
      {
        drawSVG: '0%',
        stagger: 0.05,
        ease: 'power4.easeInOut',
      },
      '0.2'
    )
    .to(q('.clarity-magni'), {
      x: -80,
      y: 220,
    })
    .to(
      q('.mask-inner'),
      {
        x: -120,
        y: 370,
      },
      '<'
    )
    .pause());

  const direction = (tl.current = gsap
    .timeline({ defaults: { duration: 1 } })
    .from(q('.direction-squiggle'), {
      drawSVG: '0%',
      ease: 'power4.easeOut',
    })
    .fromTo(
      q('.direction-arrow'),
      {
        drawSVG: '100% 100%',
        ease: 'power4.easeOut',
      },
      {
        drawSVG: '0% 100%',
        ease: 'power4.easeOut',
      },
      '<'
    )
    .pause());

  const transformation = (tl.current = gsap
    .timeline({ defaults: { duration: 0.75 } })
    .from(
      q([
        '.transformation-top-arm',
        '.transformation-right-arm',
        '.transformation-bottom-arm',
        '.transformation-right-arm-watch',
      ]),
      {
        drawSVG: '0%',
        stagger: 0.01,
      }
    )
    .to(q(['.transformation-top-arm', '.transformation-right-arm', '.transformation-bottom-arm']), {
      x: 0,
      y: 0,
      drawSVG: '100%',
    })
    .to(
      q('.transformation-right-arm-watch'),
      {
        x: 0,
        drawSVG: '100%',
      },
      '<'
    )
    .from(
      q('.transformation-shape-left'),
      {
        x: -30,
      },
      '<'
    )
    .from(
      q('.transformation-shape-right'),
      {
        xPercent: 20,
      },
      '<'
    )
    .from(
      q('.transformation-shape-bottom'),
      {
        yPercent: 20,
      },
      '<'
    )
    .pause());

  const timeline = (tl.current = gsap.timeline({
    scrollTrigger: {
      trigger: holderRef.current,
      start: '5% center',
      end: '95% top',
      pin: false,
      onUpdate: (self) => {
        // On enter
        if (self.progress > 0 && self.progress < 0.3 && self.direction === 1) clarity.play();
        if (self.progress > 0.3 && self.progress < 0.6 && self.direction === 1) direction.play();
        if (self.progress > 0.6 && self.progress < 0.9 && self.direction === 1)
          transformation.play();
      },
    },
    defaults: {
      ease: 'power3.out',
      drawSVG: '100% 100%',
    },
  }));

  return {
    timeline: timeline,
  };
};
