import { gql } from '@/graphql/__generated__';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, parseUri } from '@liquorice/utils';
import CaseStudyIndexCard from '../CaseStudyIndexCard';
import { ListCardRoot } from '../ListCard';

export const CASE_STUDY_INDEX_CARD = gql(`
  fragment caseStudyIndexCard on caseStudyIndex_Entry {
    uri
    entryImage {
      ...ImageAsset
    }
    entrySummary
    entryTheme {
      ...entryTheme
    }
    pageTitle
  }
`);

const CaseStudyIndexEntryCard = withFragmentArray(CASE_STUDY_INDEX_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return <CaseStudyIndexCard data={item} />;
});

export const CaseStudyIndexListCard = withFragmentArray(CASE_STUDY_INDEX_CARD, (data) => {
  const item = firstNonNullable(data);

  if (!item) return null;

  return (
    <ListCardRoot
      item={{
        title: item.pageTitle,
        href: parseUri(item.uri),
      }}
    />
  );
});

export default CaseStudyIndexEntryCard;
