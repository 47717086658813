'use client';

import { useEntrySearchStore } from '@liquorice/next-search';
import { EntrySearchCard } from '../Search/Search.Card';
import Grid from '../ui/Grid';
import ArticleResult from './ArticleResult';

export default function ArticleResults() {
  const results = useEntrySearchStore((s) => s.results);

  return (
    <Grid rowGutter>
      {results.map((result) => {
        return (
          <Grid.Col key={result.id} md={4}>
            <ArticleResult id={result.id} typeName={(result as EntrySearchCard).__typename} />
          </Grid.Col>
        );
      })}
    </Grid>
  );
}
